<template>
  <el-dialog
    class="classes-dialog"
    :title="`${preTitle}班级`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="520px"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="年级" prop="grade">
        <tool-tips-group is-flex tips-content="选择年级后，加载对应的专业列表">
          <el-select v-model="formData.grade" @change="changeGrade">
            <el-option
              v-for="{paramValue} in gradeList"
              :label="paramValue"
              :value="paramValue"
              :key="paramValue" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="所属院系" prop="collegeId">
        <tool-tips-group is-flex tips-content="选择专业后，会自动获得学院名">
          <el-select v-model="formData.collegeId" disabled placeholder="选择专业，自动获取学院">
            <el-option
              v-for="{collegeName, id} in collegeList"
              :label="collegeName"
              :value="id"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="专业" prop="majorId">
        <el-select v-model="formData.majorId" :disabled="!majorList.length" @change="changeMajor" filterable
                   placeholder="根据年级加载专业列表">
          <el-option
            v-for="{majorName,id} in majorList"
            :label="majorName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="班级名称" prop="className">
        <el-input v-model.trim="formData.className" />
      </el-form-item>
      <el-form-item label="选择辅导员">
        <el-select
          v-model="formData.managerUserId"
          filterable
          @change="handleChangeManagerUser"
          placeholder="选择辅导员"
          :loading="listSysUserByRealNameLoading">
          <el-option
            v-for="{realName, id} in managerList"
            :key="id"
            :label="realName"
            :value="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="辅导员电话" prop="managerUserPhone">
        <el-input v-model.trim="formData.managerUserPhone" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('班级',addClasses)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addClasses, getClassesById } from '@/api/classes'
import { getUserAll } from '@/api/system/user-api'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'addClasses',
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      formData: {
        id: '',
        collegeId: '', // 院系
        // campusId: '', // 校区
        className: '', // 班级名称
        classNo: '', // 班级编号
        managerUserId: '', // 班主任id
        grade: '', // 年级
        majorId: '', // 专业
        managerUserName: '',
        managerUserPhone: '',
        managerSysUsername: '',
        remarks: ''
      },
      teacherList: [],
      gradeList: [],
      managerList: [],
      listSysUserByRealNameLoading: false,
      rules: {
        className: [
          {
            required: true,
            message: '请输入班级',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        majorId: {
          required: true,
          message: '请选择专业',
          trigger: 'blur'
        },
        managerUserPhone: this.$store.state.commonValidate.validatorPhoneObj,
        grade: {
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.gradeList = this.$store.state.systemParam.grade
    this.getCollegeList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getClassesById).then(() => {
          this.getMajorById(this.formData.majorId)
          this.getMajorList({ grade: this.formData.grade })
        })
      }
    })
  },
  methods: {
    addClasses, // 保存api
    // 根据id获取专业信息
    getMajorById (majorId) {
      this.$http.getMajorById(majorId).then(res => {
        if (this.formData.collegeId !== res.data.collegeId) {
          this.formData.managerUserId = ''
          this.formData.managerUserName = ''
          this.formData.managerUserPhone = ''
          this.formData.managerSysUsername = ''
        }
        this.formData.collegeId = res.data.collegeId
        this.getManagerList(this.formData.collegeId)
      })
    },
    // 选择专业
    changeMajor (majorId) {
      this.formData.collegeId = ''
      // this.formData.campusId = ''
      this.getMajorById(majorId)
    },
    // 选择年级list
    changeGrade (grade) {
      this.formData.majorId = ''
      this.getMajorList({
        grade: grade
      })
    },
    // 根据collegeId获取辅导员list
    getManagerList (collegeId) {
      getUserAll({ collegeId: collegeId }).then(res => {
        this.managerList = res.data
      })
    },
    // 获取辅导员信息
    handleChangeManagerUser (id) {
      this.managerList.forEach(item => {
        if (item.id === id) {
          this.formData.managerUserId = item.id
          this.formData.managerUserName = item.realName
          this.formData.managerUserPhone = item.phone || ''
          this.formData.managerSysUsername = item.username
        }
      })
    }
  }
}
</script>
